<script lang="ts" setup>
defineProps<{
	rankLeagueType: string;
}>();
const DEFAULT_MAX_COINS = 250000;
const DEFAULT_MAX_ENTRIES = 500;

const { t } = useT();
const isGuest = useIsGuest();
const { open } = useFunrizeModals();
const loginGuard = useLoginGuard();
const { dailyWheelData, isAvailableDailyWheel, isAvailableLuckyWheel, refreshDailyWheelData, getMaxWinByWheel } =
	useWheels();
const { unlockWheel, lockWheel } = useWheelController();
const { isSpecialWheel } = useSpecialWheelDecor();

const { durationLeft, reset } = useCountdown({
	timestamp: dailyWheelData.value?.dailyWheel?.reasonValue || "",
	formatToken: "HH[h ]mm[m ]ss[s ]",
	onCountdownStop: async () => {
		await refreshDailyWheelData();
		isAvailableDailyWheel.value ? unlockWheel() : lockWheel();
	}
});

const isTimerAvailable = computed(
	() =>
		!(isAvailableLuckyWheel.value && isAvailableDailyWheel.value) &&
		dailyWheelData.value?.dailyWheel?.reasonType === "time"
);

const handleShowDailyWheel = () => {
	loginGuard({
		success: () => {
			if (isAvailableLuckyWheel.value) {
				open("LazyOModalWheelLuckySpin");
			} else {
				open("LazyOModalWheelDaily");
			}
		},
		fail: () => open("LazyOModalSignup")
	});
};

watch(
	() => dailyWheelData.value?.dailyWheel?.reasonValue,
	(value) => {
		reset(value || "");
	}
);
</script>
<template>
	<div class="wheel" @click="handleShowDailyWheel">
		<ABadge variant="skew" background="var(--carrara)" autosize>
			<AText variant="topeka" :modifiers="['semibold', 'uppercase']">
				{{ isSpecialWheel ? t("ocean wheel") : t("Funrize Wheel") }}
			</AText>
		</ABadge>

		<div class="content">
			<AText class="label text-cannes" variant="topeka" :modifiers="['bold', 'uppercase']">
				<i18n-t keypath="max{delimiter}win">
					<template #delimiter><br /></template>
				</i18n-t>
			</AText>
			<div class="prize-pool">
				<MPrizeFund variant="coins" :iconSize="40" img="/nuxt-img/cards/coins.png">
					<AText variant="tanta" :modifiers="['semibold']">
						{{ numberFormat(getMaxWinByWheel?.coins || DEFAULT_MAX_COINS) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund v-if="!isGuest" variant="entries" :iconSize="40" img="/nuxt-img/cards/entries.png">
					<AText variant="tanta" :modifiers="['semibold']">
						{{ numberFormat(getMaxWinByWheel?.entries || DEFAULT_MAX_ENTRIES) }}
					</AText>
				</MPrizeFund>
			</div>
		</div>

		<AText v-if="isTimerAvailable" class="text-conakry">
			{{ t("Next in") }}&nbsp;
			<b class="text-cannes">{{ durationLeft.replace(/00h|(\d+h)\s(\d+m)\s(\d+s)/gi, "$1 $2") }}</b>
		</AText>
		<AButton v-else variant="primary">
			<AText variant="toledo" :modifiers="['uppercase', 'semibold']">
				{{ t("Spin & win") }}
			</AText>
		</AButton>

		<MHomeActivityWheelPicture
			:rank-league-type="rankLeagueType"
			:is-wheel-available="isAvailableLuckyWheel || isAvailableDailyWheel"
		/>
	</div>
</template>
<style lang="scss" scoped>
.wheel.item {
	padding-top: gutter(5.25);
	justify-content: space-between;

	@include media-breakpoint-down(md) {
		padding: gutter(14) 0 gutter(1.5);
		align-items: center;
	}

	.badge {
		position: absolute;
		left: 0;
		top: 0;
		color: var(--cannes);
		z-index: 10;
	}

	button {
		width: 165px;
		z-index: 10;

		@include media-breakpoint-down(md) {
			width: 135px;

			> span {
				font-size: 14px;
			}
		}
	}

	.content {
		display: flex;
		align-items: center;
		gap: gutter(0.5);
		z-index: 10;

		@include media-breakpoint-down(md) {
			flex-wrap: wrap;
			justify-content: center;

			span.tanta {
				font-size: 16px;
			}
		}

		.prize {
			gap: gutter(0.5);

			&-pool {
				display: flex;
				flex-direction: column;
				gap: gutter(0.5);

				&:deep(img) {
					width: 24px;
					height: 24px;
				}

				@include media-breakpoint-down(md) {
					align-items: center;

					&:deep(img) {
						width: 20px;
						height: 20px;
					}
				}
			}
		}

		.label {
			margin-right: gutter(0.5);

			@include media-breakpoint-down(md) {
				max-width: none;
				width: 100%;
				text-align: center;
				margin-right: 0;

				br {
					display: none;
				}
			}
		}
	}

	&.full-width {
		padding: gutter(4.375) 0 gutter(2.5) gutter(1.5);
		align-items: flex-start;

		.content {
			justify-content: flex-start;
		}

		.label {
			text-align: left;
		}
		.image-holder {
			width: 190px;
			top: 0;
			transform: none;
		}
	}
}
</style>
